body {
    margin: 0;
    font-family: 'Poppins' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

::-moz-selection {
    /* Code for Firefox */
    color: red;
    background: yellow;
}

::selection {
    color: rgba(58, 180, 67, 1);
    background: rgba(58, 180, 67, 0.1);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: #f87d4e;
    transition: opacity 0.15s ease-in-out;
}

a:hover {
    opacity: 50%;
    transition: opacity 0.15s ease-in-out;
}


.tableContainer {
    background: #FFFFFF;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 15px;
    margin-top: 32px;
    overflow: hidden;
    position: relative;
    padding-bottom: 75px
}

.tableText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #404040;
    padding: 10px 0px;
}

.tableSearchBox {
    margin-top: -10px;
    width: 375px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 15px;
}

.searchText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #A3A3A3;

}

.tableTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #404040;

}

.tableText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: -10px;
    margin-top: -10px;
}

.tableStatus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-top: -10px;
    margin-bottom: -10px;
    color: #3AB44D;
}

.tableAction {
    margin-top: -15px;
    margin-bottom: -15px;
    height: 32px;
    border-radius: 50px;
    padding: 0px 15px
}

.ant-table-thead>tr>th {
    background-color: white !important;
}

.ant-table-thead .ant-table-cell {
    font-weight: bold;
    padding-bottom: 6px !important;
}

.ant-table-cell {
    font-size: 13px !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
    font-weight: bold;
    padding-bottom: 10px !important;
}

#manageSub .ant-table-thead {
    border-radius: 30px !important;
}

#manageSub .ant-table-thead .ant-table-cell {
    background-color: #e3e3e345 !important;
    border-bottom: 0.5px solid #c3c3c3;
    border-left: 0.5px solid #c3c3c3;
    border-right: 0.5px solid #c3c3c3;
    font-weight: bold;
    padding-bottom: 10px !important;
}

.ant-table-thead .ant-table-cell {
    font-size: 13px !important;
    cursor: auto;
}

.ant-table-cell {
    font-size: 12px !important;
}

.ant-table-cell {
    cursor: pointer;
}

.ant-table-filter-column {
    justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
    flex: inherit;
}

.ant-select .ant-select-selector {
    border-radius: 20px !important;
    height: 46px !important;
    padding: 6px 15px !important
}

.ant-select-selector:has(#otp) {
    border-radius: 20px !important;
    /* height: 52px !important; */
    padding: 6px 17px !important;
    height: 46px !important;
}


.ant-select-selector:has(#roleSelect) {
    border: 1px solid #A3A3A3 !important;
    border-radius: 25px !important;
    /* height: 52px !important; */
    padding: 10px 25px !important;
    margin-top: -10px;
    height: 52px !important;
}

.ant-select:has(#roleSelect) .ant-select-arrow {
    margin-top: -10px !important;
    margin-right: 10px;
}

.ant-select-dropdown {
    border-radius: 15px !important;
}

.ant-select-item-option {
    border-radius: 10px !important;
}

.ant-collapse-header {
    padding: 5px !important;
}

.ant-collapse-header-text {
    height: 25px;

}

.ant-modal-content {
    border-radius: 25px !important;
    padding: 25px 50px !important;
}

.ant-form-item-explain-error {
    margin-left: 15px;
}

.ant-radio-button-wrapper:where(.css-dev-only-do-not-override-16qhuaf).ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.ant-table-filter-dropdown {
    border-radius: 15px !important;
}

span:has(#dragger)>.ant-upload-list {
    width: 125px !important;
}

span:has(#dragger)>.ant-upload {
    height: 36px !important;
    border-radius: 12px !important;
}

.ant-upload.ant-upload-drag {
    border-radius: 20px !important;
}

.ant-table-cell {
    background-color: white !important;
}

#coloredTable .ant-table-cell {
    background-color: #fafafa !important;
}

#autoComplete .ant-select-selector {
    height: 46px;
    border-radius: 20px !important;
    padding: 7px 15px;
}

.ant-picker-panel-container {
    border-radius: 20px !important;
}

#autoComplete .ant-select-selection-search-input {
    padding: 5px;
    padding-top: 18px;
}

.ant-dropdown-menu-item {
    border-radius: 12px;
}

.ant-dropdown-menu {
    border-radius: 15px !important;
    padding: 0px !important;
}

.ant-pagination-item {
    border-radius: 5px !important;
}

.ant-pagination-options>.ant-select .ant-select-selector {
    border-radius: 15px !important;
    height: 36px !important;
    /* padding: 6px 15px !important */
}

.ant-popover-inner {
    border-radius: 15px !important;
}

.ant-table-body {
    overflow-y: auto !important;
}


.tableSearchBox {
    margin-top: -10px !important;
    width: 375px !important;
    height: 46px !important;
    background: #FFFFFF !important;
    border: 1px solid #D4D4D4 !important;
    border-radius: 15px !important;
}

#subscription .ant-select-selector {
    height: 32px !important;
    padding: 0px 15px !important;
}

#smallSelect .ant-select-selector {
    height: 32px !important;
    padding: 0px 15px !important;
}