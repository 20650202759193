.cardShadow {
    background: #FFFFFF;
    /* Shadow/Large */

    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 15px;
    padding: 25px 20px;
    transition: all 0.25s ease-in-out;
}

.cardTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: -0.02em;

    /* Neutral/Main (900) */

    color: #171717;
}

.modalContainer {
    padding: 10px 0px;
    position: relative;

}

.modalTitle {
    /* Heading/H3/Medium/Mobile */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 129% */

    /* text-align: center; */
    letter-spacing: -0.02em;

    /* Neutral/Main (900) */

    color: #171717;
    margin-bottom: 0px;
}

.modalSubTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;

    text-align: center;
    letter-spacing: -0.02em;

    color: #262626;
    margin-bottom: 40px;
}

.input {
    /* border: 1px solid #A3A3A3 !important; */
    border-radius: 20px !important;
    /* height: 52px !important; */
    padding: 10px 15px !important;
    display: flex;
    align-items: center;
    height: 48px;
    /* margin-top: -10px; */
    background: none;
}

.addEmployeeBtn {
    padding: 5px 20px !important;
    gap: 8px !important;
    height: 48px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 50px !important;
    margin-top: 25px !important;
}


.formLabel {
    /* Heading/H6/Medium/Mobile */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;
    margin-top: -10px;

}

.closeBtn {
    position: absolute !important;
    border-radius: 50px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 0px !important;
    color: #888 !important;
    font-size: 15px !important;
    top: 0px !important;
    right: -20px !important;
}

.topNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: red;
    font-size: 8px;
    color: white;
    position: absolute;
    right: -5px;
    top: 0px;
}

.customDropdownItem {
    display: flex;
    color: gray;
    align-items: center;
}

.customDropdownItem:hover {
    color: black;
}

.loaderContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: -100px;
}